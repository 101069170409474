import React from 'react';
import PropTypes from 'prop-types';

import MuuuLogo from '../../../images/common/muuu-logo.png';

export default class SpHeaderModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: this.props.imageUrl.length ? this.props.imageUrl : MuuuLogo,
      showableClassName: ''
    };

    this.onToggleOpenModal = this.onToggleOpenModal.bind(this);
  }

  /*
  showableClassNameに値が入っている場合は削除し、入っていない場合にはis-showを入れる
   */
  onToggleOpenModal() {
    this.setState((prevState) => ({ showableClassName: prevState.showableClassName.length ? '' : 'is-show' }));
  }

  render() {
    return (
      <>
        <div className="menu__icon__sp" onClick={this.onToggleOpenModal} />
        <div className={this.state.showableClassName} id="modal__sp__menu">
          <div className="bg" />
          <nav>
            <span className="__close" onClick={this.onToggleOpenModal}>閉じる</span>
            <ul>
              <li>
                <a href="/creators/terms">
                  <span className="icon goods" />
                  アイテムをつくる
                </a>
              </li>
              <li>
                <a href="/creators/announcements">
                  <span className="icon announcements" />
                  お知らせを見る
                </a>
              </li>
              <li>
                <a href={this.props.mypageUrl}>
                  <span className="icon mypage" style={{ backgroundImage: `url('${this.state.imageUrl}')` }} />
                  マイページへ
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </>

    );
  }
}

SpHeaderModal.defaultProps = {
  imageUrl: ''
};

SpHeaderModal.propTypes = {
  imageUrl: PropTypes.string,
  mypageUrl: PropTypes.string.isRequired
};
